@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiButton-root:hover {
	color: white;
	background-color: red;
}
section {
	scroll-snap-align: start;
}

.scrollContainer {
	scroll-snap-type: y mandatory;
	overflow-y: scroll;
	height: 100vh;
}

.home-section-one {
	height: 60vh;
}

.home-section-two {
	height: 60vh;
}

.about-quote-section {
	height: 60vh;
}

.home-section-five {
	height: 50vh;
}

.about-services-section {
	height: 50vh;
}

.about-price-section {
	height: 40vh;
}

.about-events-section {
	height: 40vh;
}

.about-mission-section {
	height: 65vh;
}

.home-section-three {
	height: 60vh;
}

.about-contact-section {
	height: 40vh;
}

.home-section-four {
	height: 50vh;
}

.three {
	height: 100vh;
}

.contact-app-bar-section {
	height: 8vh;
}

.contact-section-one {
	height: 50vh;
}

body {
	font-family: "Poppins";
}

* {
	-ms-overflow-style: none;
}
::-webkit-scrollbar {
	display: none;
}
